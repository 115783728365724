<template>
    <div class="app-container">
        <div id="chartContainer" class="p-2" style="width: 100%; height:100%"></div>
    </div>
</template>

<script setup lang="ts">
import { useDataObjectEventListener } from 'o365.vue.composables.EventListener.ts';
import { getOrCreateDataObject, getOrCreateProcedure } from 'o365.vue.ts';
import { onMounted, ref, defineExpose } from 'vue';
import $t from "o365.modules.translate.ts";
import utils from "o365.modules.utils.js";


var chart = null;

const props = defineProps({
    dataSource: {
        type: Object,
        required: true
    },
    series: {
        type: Object,
        required: true
    },
    seriesPeriodic: {
        type: Object,
        required: false
    },
    hoursSeries: {
        type: Object,
        required: false
    },
    hoursSeriesPeriodic: {
        type: Object,
        required: false
    },
    dataSourceForHours: {
        type: Object,
        default: null
    },
    zoomType: {
        type: String,
        default: null
    },
    chartTitle: {
        type: String,
        default: null
    },
    chartType: {
        type: String,
        default: 'costs' //hours
    },
    yAxis: {
        type: Object,
        default: [
            {
                title: {
                    text: $t('')
                }
            },
            // {
                // title: {
                    // text: $t('Periodic')
                // },
                // opposite: true,
                // visible: Object(JSON.parse(localStorage.getItem('costProgressCurveChartSettings'))).showPeriodic ?? true, //this will work only for startup
                // isPeriodic: true
            // }
        ]
    }
});



const dsProjectBaselineArchives = getOrCreateDataObject({
    id: 'dsProjectBaselineArchives',
    viewName: 'aviw_Cost_ProjectBaselineArchives',
    maxRecords: 2,
    uniqueTable: '',
    allowInsert: false,
    allowDelete: false,
    allowUpdate: false,
    loadRecents: false,
    distinctRows: false,
    fields:
            [
                {name: "ArchiveID", type: "number", sortOrder:"1", direction:"asc"},
                {name: "Baseline_ID", type: "number" },
                {name: "BaselineName", type: "string" },
                {name: "Project_ID", type: "number" },
                {name: "BaselineType", type: "string" },   
                //{name: "ArchiveName", type: "string" }
            ]
});


// useDataObjectEventListener(dsProjectBaselineArchives, 'DataLoaded', () => {
dsProjectBaselineArchives.on("DataLoaded", () => {
    if (chart) {
        createDataSet();
    } else if (document.querySelector("#chartContainer")) {
        createChart();
    }
});




useDataObjectEventListener(props.dataSource, 'DataLoaded', () => {
    let projectId = props.dataSource.current.OrgUnit_ID || '-1';
    dsProjectBaselineArchives.recordSource.whereClause = "Project_ID = " + projectId;
    dsProjectBaselineArchives.load();

});

if(props.dataSourceForHours) {
    useDataObjectEventListener(props.dataSourceForHours, 'DataLoaded', () => {
        let projectId = props.dataSourceForHours.current.OrgUnit_ID || '-1';
        dsProjectBaselineArchives.recordSource.whereClause = "Project_ID = " + projectId;
        dsProjectBaselineArchives.load();
    });
}



const chartConfig = {
    chartType: props.chartType,
    title:'',
    costDataSource:props.dataSource,
    hoursDataSource:props.dataSourceForHours,
    actualDataSource:Object
}


const createChart = () => {
    const mergedOptions = {
        chart: {
            events: {
                beforePrint() {
                    this.setSize(992, 600, false); // @width, @height, @animation
                },
                afterPrint() {
                    this.setSize(null, null, false);
                }
            },
            zoomType: props.zoomType,
            // scrollablePlotArea: { //only one axis works, not both
                // minHeight: 600,
                // scrollPositionY: 1,
                // minWidth: 300,
                // scrollPositionX: 0,
            // }
        },

        title: {
            text: props.chartTitle || chartConfig.title,
            style: {
                fontSize: '15px',
                fontFamily: 'system-ui'

            }
        },
        accessibility:{
            enabled: false
        },

        yAxis: props.yAxis,

        xAxis: {
            accessibility: {
                rangeDescription: '',
            },
            type: "datetime",
            labels: {
                enabled: true,
                step: 5,
                rotation: 90
            }
        },

        // colors: ['#F15C80', '#8085E9', '#F7A35C', '#80A35C', '#2B908F', '#e1e1ef', '#983422', '#a32020'],

        plotOptions: {
            series: {
                events: {
                    legendItemClick: function (e) {
                        //this fires before series visibility is toggled
                        onLegendItemClick(e);
                    }
                },
                marker: {
                    enabled: false
                }
            }
        },

        legend: {
            enabled: Object(JSON.parse(localStorage.getItem('costProgressCurveChartSettings'))).showLegend ?? true
        },

        exporting: {
            buttons: {
                contextButton: {
                    menuItems: getMenuItems()
                }
            },
            menuItemDefinitions: {
                costs: {
                    onclick: function () {
                        chartConfig.chartType = 'costs';
                        createChart();
                    },
                    text: chartConfig.chartType === 'costs' ? '<i class="bi bi-check"></i> Costs' : 'Costs'
                },
                hours: {
                    onclick: function () {

                        chartConfig.chartType = 'hours';
                        createChart();
                    },
                    text: chartConfig.chartType === 'hours' ? '<i class="bi bi-check"></i> Hours' : 'Hours'
                },
            }
        },

        credits: {
            enabled: false
        },

        tooltip: {
            crosshairs: true,
            shared: true,
            valueSuffix: "",
        },

    };

    chart = Highcharts.chart('chartContainer', mergedOptions);
    chart.showLoading();
    getCurrentDataSource().load();
    //props.dataSource.load();
    window.exposedChart = chart;
};

const getCurrentDataSource = () => {
    return chartConfig.chartType === 'hours' ? props.dataSourceForHours : props.dataSource;
}
const getCurrentSeries = () => {
    let series = chartConfig.chartType === 'hours' ? props.hoursSeries : props.series;
    let periodicSeries = null;

    const storageSettings = JSON.parse( localStorage.getItem('costProgressCurveChartSettings') );
    if(storageSettings?.showPeriodic) {
        periodicSeries = chartConfig.chartType === 'hours' ? props.hoursSeriesPeriodic : props.seriesPeriodic;
    }

    series = {
        ...series, 
        ...periodicSeries, 
        ...getAdditionalSeries()
    };

    // const seriesExample = {
        // PlannedValue: {
            // name: $t("Planned"),
            // VVV this will be needed when we want to store series visibility and avoid problems with translated or generated series names
            // uniqueName: 'Plannedvalue' //You can omit or specify this, but do not translate or generate
            // data: [],
            // color: "#0099FF",
            // visible: true,
        // }
    // }
    for(var item in series) {
        if(!series[item].uniqueName) {
            series[item].uniqueName = item;
        }
    }

    const storageSeriesVisibility = JSON.parse( localStorage.getItem('costProgressCurveChartSeriesVisibility') );

    if(storageSeriesVisibility) {
        for(var item in series) {
            let uniqueName = series[item].uniqueName;
            if(storageSeriesVisibility.hasOwnProperty(uniqueName)){
                series[item].visible = storageSeriesVisibility[uniqueName];
            }
        }
    }

    return series;
}

const createDataSet = () => {
    let currentDataObject = getCurrentDataSource();
    let currentSeries = getCurrentSeries();

    let plotLines = [];
    let xCategories = currentDataObject.getData().map((row) => utils.formatDate(row.CutOff || row.Cutoff, "MM.yyyy"));
    let xSteps = scaleXAxis(xCategories.length);

    clearChart(chart, currentSeries);
    chart.xAxis[0].options.labels.step = xSteps;

    chart.xAxis[0].categories = xCategories;
    chart.options.exporting.chartOptions = {
        xAxis: {
            labels: {
                enabled: true,
                step: xSteps
            },
            categories: xCategories
        }
    }

    // currentDataObject.getData().forEach(function (pRow, pIndex) {
        // probably covered in clearChart
        // pRow._fields.forEach((i) => {
            // if (currentSeries.hasOwnProperty(i.name)) {
                // currentSeries[i.name].data = [];
            // }
        // })
    // });
    currentDataObject.getData().forEach(function (pRow, pIndex) {
        pRow._fields.forEach((i) => {
            if (currentSeries.hasOwnProperty(i.name)) {
                currentSeries[i.name].data.push([pRow[i.name]]);
            }
        })

        if (pRow.IsCurrentCutoff) {
            plotLines.push(chart.xAxis[0].addPlotLine({
                value: pIndex,
                color: 'grey',
                label: {
                    text: $t('Current Cutoff'),
                    align: 'centre'
                },
                width: 1,
                id: 'first',
                dashStyle: 'dash'
            }));
        }
    });
    for (var vSeriesKey in currentSeries) {
        if (currentSeries.hasOwnProperty(vSeriesKey)) {
            chart.addSeries(currentSeries[vSeriesKey], false);
        }
    }

    chart.hideLoading();
    chart.redraw();
};

const setChartTitle = (x) => {
    chart.setTitle({ text: x });
    chartConfig.title = x;
};

const getMenuItems = () => {
    let menu = [
        "viewFullscreen",
        toggleChartLegendButton(),
        //togglePeriodicValues(),
        "printChart",
        "downloadPNG",
        "downloadJPEG",
        "downloadPDF"
    ];

    if (props.dataSourceForHours != null) {
        menu.push("separator", "hours", "costs");
    }
    return menu;
};

const toggleChartLegendButton = () => {
    return {
        text: 'Toggle Legend',
        onclick: function () {
            let legend = chart.legend;
            let storageSettings = JSON.parse(localStorage.getItem('costProgressCurveChartSettings')) ?? {};

            if (legend.display) {
                // //Hide the legend
                chart.legend.update({ enabled: false });
                storageSettings.showLegend = false;
                localStorage.setItem('costProgressCurveChartSettings', JSON.stringify(storageSettings))

            } else {
                // //Show the legend
                chart.legend.update({ enabled: true });
                storageSettings.showLegend = true;
                localStorage.setItem('costProgressCurveChartSettings', JSON.stringify(storageSettings))
            }
        }
    }
};

// const togglePeriodicValues = () => {
    // return {
        // text: 'Toggle Periodic',
        // onclick: function () {
            // let storageSettings = JSON.parse(localStorage.getItem('costProgressCurveChartSettings')) ?? {};
            // let periodicAxisIndex = chart?.yAxis ? chart.yAxis.findIndex( axis => axis?.userOptions?.isPeriodic === true ) : null;

            // if (storageSettings?.showPeriodic) {
                //Hide the periodic values
                // storageSettings.showPeriodic = false;
                // localStorage.setItem('costProgressCurveChartSettings', JSON.stringify(storageSettings));

                // createChart();
                // if(periodicAxisIndex) {
                    // chart.yAxis[periodicAxisIndex].update({visible: false}); //must go after redraw
                // }
                
            // } else {
                //Show the periodic values
                // storageSettings.showPeriodic = true;
                // localStorage.setItem('costProgressCurveChartSettings', JSON.stringify(storageSettings));

                // createChart();
                // if(periodicAxisIndex) {
                    // chart.yAxis[periodicAxisIndex].update({visible: true}); //must go after redraw
                // }
            // }
        // }
    // }
// };


function scaleXAxis(pDataLength) {
    if (pDataLength < 40) {
        return Math.ceil((pDataLength + 1) / 10);
    } else if (pDataLength < 50) {
        return 6;
    }
    return 12;
}

function clearChart(pChart, currentSeries) {
    pChart.xAxis[0].removePlotLine("first");

    for (let i = pChart.series.length; i > 0; i--) {
        pChart.series[0].remove(false);
    }
    for (let property in currentSeries) {
        if (currentSeries.hasOwnProperty(property)) {
            currentSeries[property].data = [];
        }
    }
}

const getAdditionalSeries = () => {


    let chartSeriesForArchives = {
        // Archive1Total: {
            // name: $t("Archive1Total"),
            // uniqueName: 'Archive1Total',
            // data: [],
            // color: "#00FF00",
            // step: "right",
            // visible: false,
        // },
        // Archive1Value: {
            // name: $t("Archive1Value"),
            // uniqueName: 'Archive1Value',
            // data: [],
            // color: "#00FF00",
            // step: "right",
            // visible: false,
        // },
        Archive1Progress: {
            name: $t("Archive1Progress"),
            uniqueName: 'Archive1Progress',
            data: [],
            color: "#00FF00",
            step: "right",
            visible: false,
        },
        // Archive2Total: {
            // name: $t("Archive2Total"),
            // uniqueName: 'Archive2Total',
            // data: [],
            // color: "#00FF00",
            // step: "right",
            // visible: false,
        // },
        
        // Archive2Value: {
            // name: $t("Archive2Value"),
            // uniqueName: 'Archive2Value',
            // data: [],
            // color: "#00FF00",
            // step: "right",
            // visible: false,
        // },
        
        Archive2Progress: {
            name: $t("Archive2Progress"),
            uniqueName: 'Archive2Progress',
            data: [],
            color: "#00FF00",
            step: "right",
            visible: false,
        },
    };

    removeUnusedSeries(chartSeriesForArchives);
    renameArchiveSeries(chartSeriesForArchives);

    return chartSeriesForArchives;
}

const renameArchiveSeries = (chartSeriesForArchives) =>{
    dsProjectBaselineArchives.getData().forEach(x => {
        const archiveID = x.ArchiveID;

        for (const key in chartSeriesForArchives) {
            if (archiveID === 1 && key.startsWith("Archive1")) {
                chartSeriesForArchives[key].name = chartSeriesForArchives[key].name.replace('Archive1', x.BaselineName + ' - ') ; // Change the name for Archive2
            } else if (archiveID === 2 && key.startsWith("Archive2")) {
                chartSeriesForArchives[key].name = chartSeriesForArchives[key].name.replace('Archive2', x.BaselineName + ' - ') ; // Change the name for Archive2
            }
        }
    });
}

const removeUnusedSeries = (chartSeriesForArchives) => {
    // Array to store unique ArchiveIDs from dsProjectBaselineArchives.getData()
    const uniqueArchiveIDs = new Set();

    // Iterate over the data from dsProjectBaselineArchives
    dsProjectBaselineArchives.getData().forEach(x => {
        uniqueArchiveIDs.add(x.ArchiveID);
    });

    // Iterate over the keys of chartSeriesForArchives
    for (const key in chartSeriesForArchives) {
        // Extract the ArchiveID from the key
        const archiveID = key.match(/\d+/)[0]; // Extracts the numeric part from the key

        // Check if the uniqueArchiveIDs set does not contain the ArchiveID
        if (!uniqueArchiveIDs.has(parseInt(archiveID))) {
            // If it doesn't exist, delete the entry from chartSeriesForArchives
            delete chartSeriesForArchives[key];
        }
    }

}

const getChartObject = () => {
    return Highcharts.charts[Highcharts.attr(document.getElementById('chartContainer'), 'data-highcharts-chart')];
}

function onLegendItemClick(e) {
    let targetSeries = e.target;
    let uniqueName = targetSeries.userOptions.uniqueName;
    let storageSeriesVisibility = JSON.parse( localStorage.getItem('costProgressCurveChartSeriesVisibility') ) ?? {};
    storageSeriesVisibility[uniqueName] = !e.target.visible;
    localStorage.setItem('costProgressCurveChartSeriesVisibility', JSON.stringify(storageSeriesVisibility) );
}

onMounted(() => {
    if (document.querySelector("#chartContainer")) {
        createChart();
    }
});

defineExpose({
    setChartTitle,
    createChart
}); 
</script>